import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        menu(locale: $locale) {
          logo {
            text
            link
            analyticsEvent
          }
          login {
            text
            link
            analyticsEvent
          }
          groups {
            ... on MenuNavigationCategoryRecord {
              id
              title
              _modelApiKey
              subcategories {
                id
                title
                links {
                  id
                  text
                  link
                  analyticsEvent
                  new
                }
              }
            }
            ... on MenuLinkRecord {
              id
              link
              text
              analyticsEvent
              new
              _modelApiKey
            }
          }
          newChipLabel
          openMenuLabel
          closeMenuLabel
          jumpToContentLabel
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    menu: messages.menu,
  };
}
