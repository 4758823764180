import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        businessCardPageNew(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          heroTitle
          heroSubtitle
          heroQuickNavId
          heroQuickNavTitle
          heroFormTitle
          heroFormSubmitLabel
          heroTitleV2
          imageContainer {
            imageDesktop {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageTablet {
              alt
              url
            }
          }
          cardsAreaV2 {
            ... on BusinessAccountPageCardSimpleRecord {
              id
              title
              description
              ctaText
              ctaLink
              componentName
              quickNavId
              quickNavText
              secondCtaText
              secondCtaLink
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on BusinessAccountPageFaqRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              faqQuestions {
                id
                title
                content
                slug
              }
            }
            ... on BusinessAccountPageFeatureRecord {
              id
              title
              titleTag
              componentName
              quickNavId
              quickNavText
              videoPath
              subtitle
              ctaText
              ctaLink
            }
            ... on BusinessAccountPageBenefitRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              benefits {
                icon
                title
                id
              }
            }
            ... on BusinessAccountPageEligibilityRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              waitlistLabel
              sections {
                id
                title
                content
                hasWaitlist
              }
            }
            ... on BusinessAccountProductRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              products {
                id
                title
                content
                chipText
              }
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on BusinessAccountPageBlogRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              posts {
                ctaLink
                ctaText
              }
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    businessCardPageNew: messages.businessCardPageNew,
  };
}
