import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        emailOptOutPage(locale: $locale) {
          headSeo {
            description
            title
          }
          headSeoBreadcrumbTitle
          id
          contentArea {
            title
            description
            componentName
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    emailOptOutPage: messages.emailOptOutPage,
  };
}
