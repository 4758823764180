import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        faqPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }

          pageTitle
          faqSection {
            faqCategoryTitle
            faqCategory {
              slug
            }
          }
        }
        head: allFaqQuestions(locale: $locale, first: 100) {
          category {
            slug
            title
          }
          slug
          title
          content
        }
        tail: allFaqQuestions(locale: $locale, skip: 100, first: 100) {
          category {
            slug
            title
          }
          slug
          title
          content
        }
      }
    `,
    variables: {
      locale,
    },
  });

  messages.faqPage.faqSection = messages.faqPage.faqSection.map(faqSectionCategory => ({
    ...faqSectionCategory,
    faqCategory: {
      ...faqSectionCategory.faqCategory,
      // DatoCMS only fetches 100 items at a time, so we need to merge the results of two queries
      faqQuestions: [...messages.head
        .filter(faqQuestion => {
          if (!faqQuestion.category || !faqSectionCategory.faqCategory) {
            return false;
          }
          return faqQuestion.category.slug === faqSectionCategory.faqCategory.slug;
        }), ...messages.tail
        .filter(faqQuestion => {
          if (!faqQuestion.category || !faqSectionCategory.faqCategory) {
            return false;
          }
          return faqQuestion.category.slug === faqSectionCategory.faqCategory.slug;
        })],
    },
  }));

  return {
    faqPage: messages.faqPage,
  };
}
