import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        emailSatisfactionSurveyPage(locale: $locale) {
          headSeo {
            title
            description
          }
          headSeoBreadcrumbTitle
          title
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    emailSatisfactionSurveyPage: messages.emailSatisfactionSurveyPage,
  };
}
