import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        securityPage(locale: $locale) {
          cardsArea {
            ... on DigitalSecuritySectionRecord {
              id
              title
              subtitle1
              subtitle2
              componentName
              image {
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on SecurityHeroRecord {
              id
              heroTitle
              heroSubtitle
              componentName
              image {
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on GenericSectionTitleSubtitleRecord {
              id
              title
              subtitle
              componentName
              videos {
                videoId
                videoThumbnail {
                  url
                }
              }
            }
            ... on SecurityScamCarouselSectionRecord {
              id
              title
              subtitle
              ctaUrl
              cta
              componentName
              carouselItens {
                title
                subtitle
                id
              }
            }
            ... on SecurityInformativeCarouselRecord {
              id
              title
              subtitle
              componentName
              informativeCards {
                cta
                ctaurl
                id
                text
              }
            }
            ... on SecurityHelpSectionRecord {
              id
              title
              subtitle
              componentName
              helpCards {
                id
                telNumber
                telNumber2
                telText
                telText2
                title
                ctaUrl
                description
                ctaTitle
              }
            }
            ... on GeneralFaqSectionRecord {
              id
              title
              componentName
              ctaText
              faqItems {
                ctaUrl
                ctaText
                id
                title
                content
              }
            }
            ... on SecurityPageSosSectionRecord {
              id
              subtitle
              subtitle2
              urlVideo
              ctaLink
              ctaText
              componentName
              imageVideo {
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  url
                  alt
                }
              }
              cards {
                icon
                description
                id
              }
              content {
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
          }
          headSeo {
            description
            title
          }
          headSeoBreadcrumbTitle
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    securityPage: messages.securityPage,
  };
}
