import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        regulatoryRequestPage(locale: $locale) {
          title
          subtitle
          informativeSectionSubtitle
          informativeSectionTitle
          requestSectionTitle
          requestSectionSubtitle
          requestSectionAcceptTerms
          requestSectionLegalText
          requestSectionButton {
            link
            text
          }
          otherRequestsSectionTitle
          otherRequestsSection {
            title
            iconName
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    regulatoryRequestPage: messages.regulatoryRequestPage,
  };
}
