import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
     query($locale: SiteLocale) {
      emailVerificationPage(locale: $locale) {
        headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          id
          errorMessage
          formTitle
          successSubtitle
          successTitle
          confirmationButton
        }
      }
   `,
    variables: {
      locale,
    },
  });

  return {
    emailVerificationPage: messages.emailVerificationPage,
  };
}
