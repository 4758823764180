import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        lendingNuPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          cardsArea {
            ... on GeneralFaqSectionRecord {
              id
              componentName
              ctaLink
              ctaText
              quicknavId
              quicknavTitle
              title
              faqItems {
                title
                slug
                position
                id
                content
              }
            }
            ... on LendingPageHeroSectionRecord {
              id
              componentName
              ctaLink
              ctaText
              quicknavId
              quicknavTitle
              shortFormTitle
              subtitle1
              subtitle2
              title
              lendingPageSectionHeroImage {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on LendingPageSectionBlogRecord {
              id
              quicknavId
              quicknavTitle
              title
              componentName
              sectionContent {
                id
                link
                text
              }
            }
            ... on LendingPageSectionControlRecord {
              id
              componentName
              quicknavId
              quicknavTitle
              title
              topics {
                id
                title
                description
              }
            }
            ... on LendingPageSectionTestimonialRecord {
              id
              componentName
              description
              quicknavId
              quicknavTitle
              title
              sectionContent {
                text
                id
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });
  return {
    lendingNuPage: messages.lendingNuPage,
  };
}
