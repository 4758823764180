import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        revolvingAndInstallmentPage(locale: $locale) {
          headSeo {
            title
            description
          }
          headSeoBreadcrumbTitle
          contentArea {
            ... on RevolvingAndInstallmentPageSectionHeadingRecord {
              id
              title
              componentName
            }
            ... on RevolvingAndInstallmentPageSectionWhatsChangedRecord {
              id
              title
              image {
                url
                alt
              }
              description1
              description2
              componentName
            }
            ... on RevolvingAndInstallmentPageSectionPaymentOptionRecord {
              id
              title
              topicsArea {
                title
                image {
                  url
                  alt
                }
              }
              description1
              description2
              description3
              image {
                url
                alt
              }
              componentName
            }
            ... on RevolvingAndInstallmentPageSectionVideoRecord {
              id
              title
              description
              image {
                url
                alt
              }
              videoAltText
              youtubeVideoPath
              componentName
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    revolvingAndInstallmentPage: messages.revolvingAndInstallmentPage,
  };
}
