import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        requestUltravioletNewPage (locale: $locale) {
          cardsArea {
              ... on UltravioletaPageSectionHeroRecord {
                id
                title
                description
                lastText
                componentName
                imageArea {
                  imageDesktop {
                    url
                    alt
                  }
                  imageMobile {
                    url
                    alt
                  }
                  imageTablet {
                    url
                    alt
                  }
                }
              }
              ... on GenericCarouselSectionRecord {
                id
                title
                componentName
                ctaText
                ctaLink 
                carousel {
                  ... on GenericSimpleTopicRecord {
                    id
                    title
                    description
                    componentName
                  }
                  ... on GenericTopicRecord {
                    id
                    componentName
                    ctaLink
                    ctaText
                    description
                    iconName
                    title
                  }
                }
              }
              ... on UltravioletaPageSectionTopicRecord {
                id
                componentName
                ctaUrl
                ctaText
                description
                title
                marker
                image {
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
                topics {
                  ... on GenericCardWithImageRecord {
                    id
                    ctaText
                    title
                    description
                    image {
                      responsiveImage {
                        src
                        alt
                      }
                    }
                  }
                  ... on GenericSimpleTopicRecord {
                    id
                    title
                    description
                    componentName
                  }
                }
              }
              ... on GeneralFaqSectionRecord {
                id
                ctaLink
                ctaText
                title
                componentName
                faqItems {
                  id
                  title
                  content
                }
              }
              ... on GenericSectionRecord {
                id
                componentName
                title
                description
                imageArea {
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
              }
            }
          }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    requestUltravioletNewPage: messages.requestUltravioletNewPage,
  };
}
