import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        nuWorld(locale: $locale) {
          headSeo {
            description
            title
          }
          headSeoBreadcrumbTitle
          cardsArea {
            ... on NuWorldHeroRecord {
              id
              componentName
              knowMoreText
              title
              videoId
              buttonText
              images {
                imageTablet {
                  url
                  alt
                }
                imageMobile {
                  url
                  alt
                }
                imageDesktop {
                  alt
                  url
                }
              }
            }
            ... on CardPageSectionFeatureRecord {
              id
              componentName
              featureCards {
                ... on GenericCardWithImageRecord {
                  id
                  title
                  description
                  ctaLink
                  image {
                    url
                    alt
                  }
                }
              }
            }
            ... on GenericCarouselSectionRecord {
              id
              componentName
              title
              subtitle
              imageArea {
                ... on GenericImageContainerRecord {
                  imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
                }
              }
              carousel {
                ... on GenericSimpleTopicRecord {
                  id
                  title
                  description
                  componentName
                }
                ... on GenericTopicRecord {
                  id
                  componentName
                  ctaLink
                  ctaText
                  description
                  iconName
                  title
                }
              }
            }
            ... on GenericSectionWithShortFormRecord {
              id
              componentName
              shortFormTitle
              title
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    nuWorld: messages.nuWorld,
  };
}
