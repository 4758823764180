import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
     query($locale: SiteLocale) {
       analysisPage(locale: $locale) {
         headSeoBreadcrumbTitle
         headSeo {
           description
           title
         }
         cardsArea {
           ... on AnalysisPageSectionHeroRecord {
             id
             title
             description
             componentName
             analysisPageImages {
               imageMobile {
                 alt
                 url
               }
               imageTablet {
                 alt
                 url
               }
               imageDesktop {
                 alt
                 url
               }
             }
           }
           ... on AnalysisPageCreditAnalysisSectionRecord {
             id
             title
             componentName
             warningText
             warningTitle
             sectionCreditAnalysisStep {
               title
               description
             }
             sectionCreditAnalysisGroup {
               title
               description
             }
           }
           ... on AnalysisPageSectionNewRequestRecord {
             id
             componentName
             title
             warningText
             warningTitle
             analysisPageSectionNewRequestImage {
               imageDesktop {
                 alt
                 url
               }
               imageMobile {
                 alt
                 url
               }
               imageTablet {
                 alt
                 url
               }
             }
             stepsArea {
               title
               sectionNewRequestInstructionSet {
                 title
                 step1
                 step2
                 step3
                 ctaLink
                 ctaText
               }
             }
           }
         }
       }
     }
   `,
    variables: {
      locale,
    },
  });

  return {
    analysisPage: messages.analysisPage,
  };
}
