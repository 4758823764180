import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        requestNuPage(locale: $locale) {
          id
          heroTitle
          heroDescription
          headSeoBreadcrumbTitle
          shortFormTitle
          headSeo {
            title
            description
          }
          heroImage {
            imageDesktop {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageTablet {
              alt
              url
            }
          }
          cardsArea {
            ... on RequestNuPageSectionProductRecord {
              id
              componentName
              productCards {
                title
                description
                id
                image {
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
              }
            }
            ... on GenericSectionWithListRecord {
              id
              componentName
              title
              description
              ctaLink
              ctaText
              topics {
          ... on GenericSingleLineRecord {
              id
              text
              }
            }
              image {
                ... on GenericImageContainerRecord {
                  id
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
              }
            }
            ... on RequestNuPageSectionComplexityRecord {
              id
              componentName
              title
              description
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on GenericSectionWithIllustrationRecord {
              id
              componentName
              title
              description
              illustration {
                alt
                url
              }
            }
            ... on RequestNuPageSectionPressRecord {
              id
              title
              componentName
              newsCards {
                id
                headline
                ctaText
                ctaLink
                logo {
                  alt
                  url
                }
              }
            }
            ... on GenericSectionRecord {
              id
              title
              componentName
              imageArea {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    requestNuPage: messages.requestNuPage,
  };
}
