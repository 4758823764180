import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({
  locale,
  preview,
  cache,
}) {
  const { data: { messages } } = await CMSGraphQLClient({ preview })
    .query({
      cache,
      query: gql`
      query($locale: SiteLocale) {
        securedCardPage(locale: $locale) {
          cardsArea {
            ... on SecuredCardPageSectionHeroRecord {
              id
              title
              litleTitle
              description
              componentName
              ctaText
              image2 {
                imageDesktop {
                    url
                    alt
                  }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
              }
              image {
                id
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
              }
            }
            ... on SecuredCardOverviewRecord {
              id
              componentName
              youtubeVideoId
              title
            }
            ... on SecuredCardPageSectionAudienceRecord {
              id
              title
              title2
              description
              description2
              componentName
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on SecuredCardPageSectionHowItWorkRecord {
              id
              title
              description
              componentName
              sectionContent {
                id
                text
              }
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on SecuredCardPageSectionTestimonialRecord {
              id
              title
              componentName
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  alt
                  url
                }
              }
              sectionContent {
                text
                id
              }
              sectionAuthor {
                text
                id
              }
            }

            ... on SecuredCardPageSectionFormRecord {
              id
              componentName
              title
            }

            ... on GeneralFaqSectionRecord {
                id
                componentName
                quicknavId
                quicknavTitle
                title
                ctaText
                ctaLink
                faqItems {
                  title
                  slug
                  position
                  id
                  content
                }
              }
            ... on SecuredCardPageSectionKnowMoreRecord {
                id
                componentName
                sectionContent {
                  ... on GenericCtaRecord {
                      id
                      link
                      text
                    }
                  }
                title
            }
          }
          id
        }
      }
    `,
      variables: {
        locale,
      },
    });

  return {
    securedCardPage: messages.securedCardPage,
  };
}
