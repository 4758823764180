import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        nutapIphonePage(locale: $locale) {
          headSeo {
            description
            title
          }
          headSeoBreadcrumbTitle
          cardsArea {
            ... on NutapPageFeesSectionRecord {
              title
              quickNavId
              componentName
              fees {
                feeDetails {
                  id
                  label
                  fee
                }
              }
              cardAcceptanceText
              cardAcceptanceIcons {
                id
                icon
                title
              }
              digitalWalletsAcceptanceText
              digitalWalletsAcceptanceIcons {
                id
                icon
                title
              }
            }
            ... on GenericSectionRecord {
              id
              ctaText
              ctaLink
              componentName
              title
              description
              imageArea {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on GenericSectionWithListRecord {
              id
              description
              ctaText
              ctaLink
              componentName
              title
              image {
                ... on GenericImageContainerRecord {
                  id
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
                ... on GenericIllustrationRecord {
                  id
                  illustration {
                    url
                    alt
                  }
                }
              }
              topics {
                ... on GenericSingleLineRecord {
                  id
                  text
                }
                ... on GenericTopicRecord {
                  id
                  description
                  ctaText
                  ctaLink
                  iconName
                  title
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    nutapIphonePage: messages.nutapIphonePage,
  };
}
