import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        nutapPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          shortFormTitle
          heroTitle
          heroDescription
          heroImage {
            imageTablet {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageDesktop {
              alt
              url
            }
          }
          cardsArea {
            ... on NutapPageBenefitsSectionRecord {
              title
              quickNavId
              componentName
              benefits {
                id
                icon {
                  icon
                  title
                }
                description
              }
            }
            ... on NutapPageFeesSectionRecord {
              title
              quickNavId
              componentName
              fees {
                feeDetails {
                  id
                  label
                  fee
                }
              }
              cardAcceptanceText
              cardAcceptanceIcons {
                id
                icon
                title
              }
              digitalWalletsAcceptanceText
              digitalWalletsAcceptanceIcons {
                id
                icon
                title
              }
            }
            ... on NutapPageKnowMoreSectionRecord {
              title
              description
              quickNavId
              componentName
              image {
                url
                alt
              }
              links {
                link
                text
                id
              }
            }
            ... on NutapPageFeatureSectionRecord {
              label
              isNewLabelVisible
              title
              description
              componentName
              quickNavId
            }
            ... on NutapPageEligibilitySectionRecord {
              title
              subtitle
              quickNavId
              componentName
              tabs {
                title
                description
                id
              }
            }
            ... on NutapPageBlogSectionRecord {
              componentName
              quickNavId
              background {
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageDesktop {
                  alt
                  url
                }
              }
              title
              blogPosts {
                title
                url
              }
            }
            ... on NutapPageFaqSectionRecord {
              title
              componentName
              quickNavId
              questions {
                id
                question
                answer
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    nutapPage: messages.nutapPage,
  };
}
