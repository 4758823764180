import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
     query($locale: SiteLocale) {
       contractPage(locale: $locale) {
        componentName
        title
        headSeo {
          description
          title
        }
        headSeoBreadcrumbTitle
        cardsArea {
          ... on GenericSectionRecord {
            id
            description
            title
            componentName
          }
          ... on ContractPageGenericTableRecord {
            id
            title
            tableContent {
              tableRowTitle
              tableRowValue
            }
            componentName
          }
        }
       }
      }
   `,
    variables: {
      locale,
    },
  });

  return {
    contractPage: messages.contractPage,
  };
}
