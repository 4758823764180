import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        generatingBoletosPage(locale: $locale) {
          headSeo {
            description
            title
          }
          title
          cardsArea {
            id
            title
            topics {
              ... on GenericSingleLineRecord {
                id
                text
                _modelApiKey
              }
              ... on GenericImageRecord {
                id
                _modelApiKey
                image {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    generatingBoletosPage: messages.generatingBoletosPage,
  };
}
