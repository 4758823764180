import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
          lendingPage(locale: $locale) {
            headSeoBreadcrumbTitle
            headSeo {
              title
              description
            }
              cardsArea {
              ... on GeneralFaqSectionRecord {
                id
                componentName
                quicknavId
                quicknavTitle
                title
                ctaText
                ctaLink
                faqItems {
                  title
                  slug
                  position
                  id
                  content
                }
              }
              ... on LendingPageHeroSectionRecord {
                id
                componentName
                ctaLink
                ctaText
                title
                subtitle1
                subtitle2
                shortFormTitle
                quicknavTitle
                quicknavId
                lendingPageSectionHeroImage {
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
              }
                ... on LendingPageSectionBlogRecord {
                  id
                  quicknavId
                  quicknavTitle
                  title
                  componentName
                  sectionContent {
                    ... on GenericCtaRecord {
                      id
                      link
                      text
                    }
                  }
                  imageArea {
                    imageDesktop {
                      alt
                      url
                    }
                    imageMobile {
                      alt
                      url
                    }
                    imageTablet {
                      alt
                      url
                    }
                  }
                }
                ... on LendingPageSectionControlRecord {
                  id
                  componentName
                  quicknavId
                  quicknavTitle
                  title
                  topics {
                    description
                    title
                    id
                    iconName
                  }
                }
                ... on LendingPageSectionTestimonialRecord {
                  id
                  quicknavId
                  quicknavTitle
                  title
                  description
                  componentName
                  sectionContent {
                    text
                    id
                  }
                }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    lendingPage: messages.lendingPage,
  };
}
