import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        transparentPage(locale: $locale) {
          headSeo {
            title
            description
          }
          reasonFormTitle
          reasonFormButton
          reasonFormConfirmation
          preReasonFormText
          preReasonFormButton
          optOutFormTitle
          optOutFormConfirmation
          optOutFormButton
          headingTitle
          headingText
          cardImage {
            imageMobile {
              url
              alt
            }
            imageTablet {
              url
              alt
            }
            imageDesktop {
              url
              alt
            }
          }
          cardCta {
            text
            link
          }
          cardText
          cardTitle
          collapseText
          collapseTitle
        }
      }    
    `,
    variables: {
      locale,
    },
  });

  return {
    transparentPage: messages.transparentPage,
  };
}
