import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        pldPage(locale: $locale) {
          id
          seoBreadcrumbTitle
          headSeo {
            title
            description
          }
          cardsArea {
            ... on GenericSectionRecord {
              id
              title
              componentName
              imageArea {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on PldPageSectionMissionRecord {
              id
              title
              description
              componentName
            }
            ... on PldPageSectionDocumentRecord {
              id
              description
              title
              componentName
              docLinks {
                link
                text
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    pldPage: messages.pldPage,
  };
}
