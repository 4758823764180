import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
     query($locale: SiteLocale) {
        ethicAndCompliancePage(locale: $locale) {
          title
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          heroSection {
            imageContainer {
              imageDesktop {
                alt
                url
              }
              imageMobile {
                alt
                url
              }
              imageTablet {
                alt
                url
              }
            }
          }
          cardsAreaV2 {
            ... on EthicAndComplianceSectionInfoRecord {
              componentName
              sectionTitle
              sectionDescription
              ctaLink
              ctaText
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on EthicAndComplianceSectionProcedureRecord {
              componentName
              sectionTitle
              sectionDescription
              ctaLink
              ctaText
              image {
                alt
                url
              }
            }
          }
        }
      }
   `,
    variables: {
      locale,
    },
  });

  return {
    ethicAndCompliancePage: messages.ethicAndCompliancePage,
  };
}
