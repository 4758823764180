import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        assemblyPage(locale: $locale) {
          pageTitle
          id
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          cardsArea {
            title
            id
            componentName
            documents {
              year
              id
              reportLinks {
                link
                text
                id
              }
            }
          }
        }
      }
   `,
    variables: {
      locale,
    },
  });

  return {
    assemblyPage: messages.assemblyPage,
  };
}
