import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        investmentsPage (locale: $locale) {
          cardsArea {
            ... on SecureInvestmentSectionRecord {
              id
              componentName
              altText
              secureInvestmentsSectionSubtitle
              secureInvestmentsSectionTitle
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on InvestmentOptionsSectionRecord {
              id
              componentName
              investmentOptionsSectionTitle
              investmentIsForYouSectionAltImage
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
              investmentIsForYouSectionSlides {
                description
                ctaText
                ctaLink
                title
                id
              }
            }
            ... on NuAppInvestmentSectionRecord {
              id
              componentName
              nuAppInvestmentSectionTitle
              nuAppInvestmentTabs {
                id
                title
                description
                label
              }
            }
            ... on NuinvestAppInvestmentSectionRecord {
              id
              componentName
              nuinvestAppInvestmentSectionTabDescription4
              nuinvestAppInvestmentSectionTabDescription1
              nuinvestAppInvestmentSectionTabDescription2
              nuinvestAppInvestmentSectionTabDescription3
              nuinvestAppInvestmentSectionTabTitle1
              nuinvestAppInvestmentSectionTabTitle2
              nuinvestAppInvestmentSectionTabTitle3
              nuinvestAppInvestmentSectionTabTitle4
              nuinvestAppInvestmentSectionTitle
              nuinvestAppInvestmentSectionCtaLink
              nuinvestAppInvestmentSectionCtaText

            }
            ... on InvestmentIsForYouSectionRecord {
              id
              componentName
              investmentIsForYouSectionTitle
              investmentIsForYouSectionText1
              investmentIsForYouSectionSlides {
                id
                title
              }
            }
            ... on FaqInvestmentSectionRecord {
              id
              componentName
              faqInvestmentSectionTitle
              blogLink
              investmentsDistributionLink
              faqItems {
                id
                slug
                title
                content
              }
            }
            ... on ZeroRateSectionRecord {
              id
              zeroRateSectionAltImage
              componentName
              zeroRateSectionTitle
              zeroRateSectionTexts {
                text
                link
                linkText
                id
              }
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on LearnToInvestSectionRecord {
              id
              componentName
              learnToInvestSectionTitle
              learnToInvestSectionLink
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    investmentsPage: messages.investmentsPage,
  };
}
