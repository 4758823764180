import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        footer(locale: $locale) {
          labelEn
          labelPtBr
          legalInfo
          id
          addressInfo
          socialMediaIcons {
            name
            link
            id
            iconName
          }
          footerNavigationGroup {
            categoryName
            id
            categoryItems {
              ... on AppDownloadLinkRecord {
                id
                appStoreAlt
                appstoreLink
                ariaText
                platform
              }
              ... on FooterNavigationItemRecord {
                id
                itemLink
                itemName
                variant
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    footer: messages.footer,
  };
}
