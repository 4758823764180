import {
  CMSGraphQLClient,
  gql,
} from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        suppliersPage(locale: $locale) {
          headSeo {
            title
            description
          }
          cardsArea {
            ... on SuppliersPageBannerSectionRecord {
              id
              title
              description
              illustration {
                url
              }
              logo {
                url
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageEsgSectionRecord {
              id
              title
              firstDescription
              highlightDescription
              lastDescription
              cta {
                text
                link
              }
              illustration {
                url
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageEthicsAndComplianceSectionRecord {
              id
              title
              description
              illustration {
                url
              }
              knowMoreDescription
              cta {
                text
                link
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageHowToBecomeASupplierSectionRecord {
              id
              title
              description
              ctaText
              ctaLink
              genericImage {
                id
                image {
                  url
                }
              }
              ctaText
              ctaLink
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageKnowMoreSectionRecord {
              id
              backgroundImage {
                image {
                  url
                }
              }
              cardTitle
              cta {
                text
                link
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageOurPoliciesSectionRecord {
              id
              title
              description
              cta {
                text
                link
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPagePaymentsSectionRecord {
              id
              title
              description
              cards {
                icon
                title
                subtitle
                description
                note
              }
              moreInformation
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageRegisterAndQualificationSectionRecord {
              id
              title
              description
              readMoreText
              cards {
                icon
                title
                subtitle
                description
                note
              }
              information
              cta {
                text
                link
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageRelationshipWithSuppliersSectionRecord {
              id
              title
              description
              illustration {
                url
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageTermsAndConditionsSectionRecord {
              id
              title
              description
              illustration {
                url
              }
              cta {
                text
                link
              }
              quickNavigationId
              quickNavigationTitle
              componentName
            }
            ... on SuppliersPageContactUsSectionRecord {
              id
              title
              image {
                url
              }
              cta {
                text
                link
              }
              quickNavigationTitle
              quickNavigationId
              componentName
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    suppliersPage: messages.suppliersPage,
  };
}
