import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        mgmPjLandingPage(locale: $locale) {
          heroTitleDefault
          heroTitleCustom
          heroImage {
            imageMobile {
              url
              alt
            }
            imageTablet {
              url
              alt
            }
            imageDesktop {
              url
              alt
            }
          }
          cardsArea {
            ... on MgmPjSectionBenefitRecord {
              componentName
              sectionBenefit {
                ... on AccountCardBenefitRecord {
                  title
                  benefit {
                    iconName
                    description
                  }
                }
              }
              cta {
                text
                link
              }
            }
            ... on MgmPjSectionImageRecord {
              id
              description
              ctaText
              ctaLink
              componentName
              image {
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
                imageDesktop {
                  url
                  alt
                }
              }
            }
            ... on MgmPjSectionSocialValidationRecord {
              componentName
              title
              sectionSocialValidation {
                personName
                rating
                description
                source
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    mgmPjLandingPage: messages.mgmPjLandingPage,
  };
}
