import PropTypes from 'prop-types';
import compose from '@nubank/nuds-web/utils/functional/compose';

import defaultRouteMap from 'pages/routes.json';

export const PT_BR = 'pt-BR';
export const EN = 'en';

// FIXME A definição da variável está apenas no next.config, utilizar algum .env
export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_WWW_LOCALE || PT_BR;

export const AVAILABLE_LOCALES = {
  ptBR: {
    code: PT_BR,
    name: 'Português',
  },
  en: {
    code: EN,
    name: 'English',
  },
};

export const availableLocalesShape = PropTypes.objectOf(
  PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
);

export const intlShape = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
});

export function removeTrailingSlash(path) {
  return path.replace(/^(.+?)\/+$/, '$1');
}

function extractURLKeys(route) {
  const hasHash = route.includes('#');
  const hasQueryParam = route.includes('?');

  if (hasHash) {
    const [path, hash] = route.split('#');

    return { path, separator: '#', complement: hash };
  }

  if (hasQueryParam) {
    const [path, params] = route.split('?');

    return { path, separator: '?', complement: params };
  }

  return { path: route, separator: '', complement: '' };
}

function normalizeURLKeysPath(urlKeys) {
  const { path } = urlKeys;
  return {
    ...urlKeys,
    path: removeTrailingSlash(path),
  };
}

const parseURL = compose(
  normalizeURLKeysPath,
  extractURLKeys,
);

export function localizeRoute(route = '', locale, routeMap = defaultRouteMap, appLocale = DEFAULT_LOCALE) {
  const isErrorRoute = route.startsWith('/_error') || route.startsWith('_error');
  const isStaticRoute = route.startsWith('/docs');

  if (isStaticRoute || isErrorRoute) {
    return route;
  }

  const { path, separator, complement } = parseURL(route);

  const routeMatch = Object.values(routeMap).find(({ routes }) => routes[appLocale] === path);

  if (!routeMatch) {
    throw new Error(`Route ${path} is not defined on pages/routes.json.`);
  }

  const localizedRoute = routeMatch.routes[locale];
  let resolvedRoute = localizedRoute || path;

  if (locale !== DEFAULT_LOCALE && localizedRoute) {
    resolvedRoute = `/${locale}${resolvedRoute}`;
  }

  resolvedRoute = removeTrailingSlash(resolvedRoute);

  return [resolvedRoute, separator, complement].join('');
}

export function maybeFormatMessage(
  intl,
  keyPrefix,
  keySuffix,
  values,
) {
  const intlKey = keySuffix
    ? [keyPrefix, keySuffix].join('.')
    : keyPrefix;

  return intl.messages[intlKey]
    ? intl.formatMessage({
      id: intlKey,
    }, values)
    : null;
}

export function maybeFormatPrefixedMessage(
  intl,
  keyPrefix,
  formatMessage = maybeFormatMessage,
) {
  return (keySuffix, values) => formatMessage(intl, keyPrefix, keySuffix, values);
}

export const isRouteAvailableForLocale = (
  route,
  locale,
  routeMap = defaultRouteMap,
  appLocale = DEFAULT_LOCALE,
) => {
  const routeMatch = Object.values(routeMap)
    .find(({ routes }) => routes[appLocale] && routes[appLocale] === route);
  return !!(routeMatch && routeMatch.routes[locale]);
};
