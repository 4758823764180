import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        contactPageV2(locale: $locale) {
          title
          sections {
            id
            channels {
              id
              title
              subtitle
            }
            iconLinks {
              id
              title
              link
              icon
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    contactPageV2: messages.contactPageV2,
  };
}
