import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        requestDebitPage(locale: $locale) {
          cta {
            text
            link
          }
          text
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    requestDebitPage: messages.requestDebitPage,
  };
}
