import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getLifePageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        lifeInsurancePage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          susep
          cardsArea {
            ... on LifeInsurancePageSectionOldInsuranceRecord {
              id
              componentName
              title
              image {
                imageDesktop {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
              }
              youtubeVideo {
                videoId
                alternativeText
              }
            }
            ... on LifeInsurancePageSectionOldInsuranceGalleryRecord {
              componentName
              topics {
                id
                title
                image {
                  imageDesktop {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                }
              }
            }
            ... on LifeInsurancePageSectionKnowMoreRecord {
              id
              title
              cta {
                text
                link
              }
              image {
                imageDesktop {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
              }
              componentName
            }
            ... on LifeInsurancePageSectionHeroRecord {
              id
              componentName
              cta {
                link
                text
              }
              ctaHeadline
              description
              qrCodeText
              qrCode {
                imageDesktop {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
              }
              image {
                imageDesktop {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
              }
              title
            }
            ... on LifeInsurancePageSectionFormRecord {
              id
              title
              componentName
            }
            ... on LifeInsurancePageSectionCustomerTargetRecord {
              id
              componentName
              description
              title
              topics {
                id
                text
              }
              image {
                id
                imageDesktop {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
              }
            }
            ... on LifeInsurancePageSectionCustomCoverageRecord {
              id
              componentName
              title
              topics {
                id
                text
              }
              youtubeVideoId
            }
            ... on GenericVideoSectionRecord {
              id
              componentName
              title
              youtubeVideoId
              description
            }
            ... on GeneralFaqSectionRecord {
              id
              title
              ctaText
              ctaLink
              componentName
              faqItems {
                id
                title
                slug
                content
              }
            }
            ... on LifeInsurancePageSectionFeatureRecord {
              id
              componentName
              title
              topics {
                title
                id
                description
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    lifeInsurancePage: messages.lifeInsurancePage,
  };
}
