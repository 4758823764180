import {
  CMSGraphQLClient,
  gql,
} from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        businessAccountPageRevamp(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          heroTitle
          heroSubtitle
          heroQuickNavId
          heroQuickNavTitle
          heroFormLinkTitle
          heroFormTitle
          heroFormSubmitLabel
          imageContainer {
            imageDesktop {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageTablet {
              alt
              url
            }
          }
          titleForm
          subtitleForm
          privacyPolicyForm
          ctaForm {
            text
          }
          infoForm
          imageContainerForm {
            imageDesktop {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageTablet {
              alt
              url
            }
          }
          cardsArea {
            ... on BusinessAccountPageListInfoRecord {
              componentName
              title
              informations {
                id
                icon
                title
              }
              cta {
                text
                link
              }
              imagesContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on MgmPjSectionSocialValidationRecord {
              id
              componentName
              title
              sectionSocialValidation {
                id
                personName
                rating
                description
                source
              }
              sectionStoreValidation {
                id
                icon
                maxRating
                rating
                stars
                source
              }
            }
            ... on MgmPjSectionImageRecord {
              id
              description
              ctaText
              ctaLink
              componentName
              image {
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
                imageDesktop {
                  url
                  alt
                }
              }
            }
            ... on ProductDisplaySectionRecord {
              id
              title
              componentName
              products {
                id
                subtitle
                title
                description
                cta {
                  id
                  text
                  link
                }
                imageContainer {
                  ... on GenericImageRecord {
                    id
                    image {
                      alt
                      url
                    }
                  }
                }
              }
            }
            ... on NubankNewsSectionRecord {
              id
              componentName
              informations {
                id
                subtitle
                title
                description
                cta {
                  text
                  link
                }
                imageContainer {
                  ... on GenericImageContainerRecord {
                    id
                    imageDesktop {
                      alt
                      url
                    }
                    imageMobile {
                      alt
                      url
                    }
                    imageTablet {
                      alt
                      url
                    }
                  }
                }
                author
              }
            }
            ... on GenericSectionWithIllustrationRecord {
              id
              title
              description
              componentName
            }
            ... on BusinessAccountPageBlogRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              posts {
                ctaLink
                ctaText
              }
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on BusinessAccountPageFaqRecord {
              id
              title
              componentName
              quickNavId
              quickNavText
              faqQuestions {
                id
                title
                content
                slug
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    businessAccountPageRevamp: messages.businessAccountPageRevamp,
  };
}
