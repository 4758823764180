import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query ($locale: SiteLocale) {
        ultravioletaNewPage(locale: $locale) {
          headSeo {
            description
            title
            image {
              url
            }
          }
          headSeoBreadcrumbTitle
          cardsArea {
            ... on UltravioletaPageSectionHeroRecord {
              id
              title
              description
              lastText
              componentName
              imageArea {
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
              }
            }
            ... on GenericCarouselSectionRecord {
              id
              title
              componentName
              ctaText
              ctaLink
              carousel {
                ... on GenericSimpleTopicRecord {
                  id
                  title
                  description
                  componentName
                }
                ... on GenericTopicRecord {
                  id
                  ctaLink
                  iconName
                  id
                  title
                  description
                }
              }
            }
            ... on UltravioletaPageSectionTopicRecord {
              id
              componentName
              ctaUrl
              ctaText
              description
              title
              marker
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
              topics {
                ... on GenericCardWithImageRecord {
                  id
                  ctaText
                  title
                  description
                  image {
                    responsiveImage {
                      src
                      alt
                    }
                  }
                }
                ... on GenericSimpleTopicRecord {
                  id
                  title
                  description
                  componentName
                }
              }
            }
            ... on GeneralFaqSectionRecord {
              id
              ctaLink
              ctaText
              title
              componentName
              faqItems {
                id
                title
                content
              }
            }
            ... on GenericSectionTitleSubtitleRecord {
              id
              componentName
              videos {
                videoId
                id
                videoThumbnail {
                  alt
                  url
                }
                alternativeText
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    ultravioletaNewPage: messages.ultravioletaNewPage,
  };
}
