import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        cardPage(locale: $locale) {
          id
          cardHeroTitle
          cardHeroCtaDescription
          shortFormTitle
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          cardHeroImage {
            imageDesktop {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageTablet {
              alt
              url
            }
          }
          cardCardsArea {
            ... on CardSampleCardRecord {
              id
              cardSampleCardTitle
              cardSampleCardDescription
              componentName
              image {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on CardPageSectionFeatureRecord {
              id
              componentName
              title
              imageArea {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on CardPageSectionCarouselRecord {
              id
              componentName
              title
              carouselCards {
                title
                description
                ctaText
                ctaLink
                id
                image {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    cardPage: messages.cardPage,
  };
}
