import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        financialStatementsPage(locale: $locale) {
          headSeoBreadcrumbTitle
          title
          headSeo {
            title
            description
          }
          cardsArea {
            ... on FinancialStatementsSectionRecord {
              id
              title
              description
              componentName
              reportYear {
                year
                id
                reportLinks {
                  text
                  link
                  id
                }
              }
            }
            ... on FinancialStatementsPageSectionOtherReportRecord {
              id
              componentName
              title
              linksArea {
                link
                text
                id
              }
            }
          }
        }
      }
   `,
    variables: {
      locale,
    },
  });

  return {
    financialStatementsPage: messages.financialStatementsPage,
  };
}
