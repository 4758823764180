import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const {
    data: { messages },
  } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query getPageContent($locale: SiteLocale) {
        payrollLendingPage(locale: $locale) {
          headSeo {
            description
            title
            image {
              url
            }
          }
          headSeoBreadcrumbTitle
          cardsArea {
            ... on LendingPageHeroSectionRecord {
              id
              title
              subtitle1
              subtitle2
              shortFormTitle
              componentName
              lendingPageSectionHeroImage {
                imageDesktop {
                  url
                  alt
                }
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
              }
            }
            ... on UltravioletaPageSectionTopicRecord {
              id
              title
              componentName
              topics {
                ... on GenericSimpleTopicRecord {
                  id
                  title
                  componentName
                  description
                }
              }
            }
            ... on GeneralFaqSectionRecord {
              id
              title
              componentName
              ctaText
              faqItems {
                ctaUrl
                ctaText
                id
                title
                content
              }
            }
            ... on GenericSectionWithListRecord {
              id
              title
              componentName
              description
              ctaLink
              ctaText
              topics {
                ... on GenericTopicMultipleParagraphRecord {
                  id
                  title
                  description
                }
                ... on GenericSingleLineRecord {
                  id
                  text
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    payrollLendingPage: messages.payrollLendingPage,
  };
}
