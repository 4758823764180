import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getMobilePageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        mobileInsurancePage (locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          susep
          cardsArea {
            ... on MobileInsuranceSectionHeroRecord {
              id
              title
              description
              componentName
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on MobileInsuranceSectionHowItWorkRecord {
              id
              title
              description
              youtubeVideoId
              componentName
            }
            ... on MobileInsuranceSectionCustomCoverageRecord {
              id
              title
              description
              youtubeVideoId
              componentName
              topics {
                text
                id
              }
            }
            ... on MobileInsuranceSectionCustomerTargetRecord {
              id
              title
              description
              componentName
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on MobileInsuranceSectionFeatureRecord {
              id
              title
              componentName
              features {
                title
                description
                id
              }
            }
            ... on MobileInsuranceSectionWaitlistFormRecord {
              id
              title
              subtitle
              formTitle
              componentName
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on MobileInsuranceSectionClaimRecord {
              id
              componentName
              title
              ctaLink
              ctaText
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on MobileInsuranceSectionKnowMoreRecord {
              id
              title
              componentName
              cta {
                link
                text
              }
              imageContainer {
                imageDesktop {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageTablet {
                  alt
                  url
                }
              }
            }
            ... on GeneralFaqSectionRecord {
              id
              title
              componentName
              ctaLink
              ctaText
              faqItems {
                title
                id
                content
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    mobileInsurancePage: messages.mobileInsurancePage,
  };
}
