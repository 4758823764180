import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        prepaymentPage(locale: $locale) {
          id
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
          }
          cardsArea {
            ... on GenericSingleLineRecord {
              id
              text
            }
            ... on GenericSectionWithIllustrationRecord {
              id
              title
              description
              componentName
              illustration {
                alt
                url
              }
            }
            ... on PrepaymentPageSectionHowToRecord {
              id
              componentName
              title
              howToTopics {
                id
                text
                illustration {
                  alt
                  url
                }
              }
            }
            ... on PrepaymentPageSectionReminderRecord {
              id
              componentName
              description1
              description2
              title
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    prepaymentPage: messages.prepaymentPage,
  };
}
