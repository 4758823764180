import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        scrPage(locale: $locale) {
          pageTitle
          faqSection {
            id
            faqCategoryTitle
            faqCategory {
              title
              slug
              id
              faqQuestions {
                id
                content
                title
                slug
              }
            }
          }
        }
      }      
    `,
    variables: {
      locale,
    },
  });

  return {
    scrPage: messages.scrPage,
  };
}
