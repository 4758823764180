import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        accountPage(locale: $locale) {
          headSeoBreadcrumbTitle
          headSeo {
            title
            description
            image {
              url
            }
          }
          heroTitle
          heroDescription
          heroQuicknavId
          heroQuicknavTitle
          heroImage {
            id
            imageTablet {
              alt
              url
            }
            imageMobile {
              alt
              url
            }
            imageDesktop {
              alt
              url
            }
          }
          cardsArea {
            ... on AccountCardBenefitRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              benefit {
                id
                iconName
                title
                description
              }
              image {
                id
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageDesktop {
                  alt
                  url
                }
              }
            }
            ... on GenericSectionWithListRecord {
              id
              title
              description
              ctaText
              componentName
              quicknavTitle
              quicknavId
              image {
                ... on GenericImageContainerRecord {
                  id
                  imageDesktop {
                    alt
                    url
                  }
                  imageMobile {
                    alt
                    url
                  }
                  imageTablet {
                    alt
                    url
                  }
                }
              }
              topics {
                ... on GenericSingleLineRecord {
                  id
                  text
                }
              }
            }
            ... on AccountCardSampleRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              description
              ctaLink
              ctaText
              image {
                id
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageDesktop {
                  alt
                  url
                }
              }
            }
            ... on AccountPageSectionDebitRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              subtitle
              firstBlockTitle
              firstBlockDescription
              secondBlockTitle
              secondBlockDescription
              image {
                id
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageDesktop {
                  alt
                  url
                }
              }
            }
            ... on AccountPageSectionCalculatorRecord {
              title
              description
              observation
              quicknavTitle
              quicknavId
            }
            ... on AccountPageSectionCalculatorRecord {
              title
              componentName
              quicknavTitle
              quicknavId
            }
            ... on AccountPageSectionFeeRecord {
              title
              tableTitle
              description
              componentName
              quicknavTitle
              quicknavId
            }
            ... on AccountPageSectionSecurityRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              securityItems {
                id
                title
                description
                iconName
              }
              image {
                id
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageDesktop {
                  alt
                  url
                }
              }
            }
            ... on AccountPageSectionFaqRecord {
              title
              componentName
              quicknavTitle
              quicknavId
              faqQuestion {
                id
                title
                content
              }
              ctaLink
              ctaText
            }
            ... on AccountPageSectionCrossSellRecord {
              title
              componentName
              cardTitle
              crossSellItem {
                id
                link
                title
              }
              image {
                id
                imageTablet {
                  alt
                  url
                }
                imageMobile {
                  alt
                  url
                }
                imageDesktop {
                  alt
                  url
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    accountPage: messages.accountPage,
  };
}
