import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
     query($locale: SiteLocale) {
      billPage(locale: $locale) {
        headSeoBreadcrumbTitle
        headSeo {
          title
          description
        }
        cardsArea {
          ... on GenericSectionRecord {
            id
            componentName
            title
          }
          ... on BillPageSectionExplainRecord {
            id
            title
            componentName
            explanation {
              id
              text
              illustration {
                alt
                url
              }
            }
          }
          ... on BillPageSectionHowDoIPayRecord {
            id
            componentName
            title
            explanation {
              id
              text
              illustration {
                alt
                url
              }
            }
          }
          ... on BillPageSectionGenerateNewBoletoRecord {
            id
            title
            description
            componentName
            cta {
              link
              text
            }
          }
        }
      }
     }
   `,
    variables: {
      locale,
    },
  });

  return {
    billPage: messages.billPage,
  };
}
