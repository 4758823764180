import { CMSGraphQLClient, gql } from '@nubank/www-latam-commons/utils/cms/CMSGraphQLClient';

export default async function getPageContent({ locale, preview, cache }) {
  const { data: { messages } } = await CMSGraphQLClient({ preview }).query({
    cache,
    query: gql`
      query($locale: SiteLocale) {
        requestUltravioletPage (locale: $locale) {
          cardsArea {
            ... on GenericSectionRecord {
              id
              title
              description
              imageArea {
                imageMobile {
                  url
                  alt
                }
                imageTablet {
                  url
                  alt
                }
                imageDesktop {
                  url
                  alt
                }
              }
              componentName
            }
            ... on GenericCarouselSectionRecord {
              id
              title
              carousel {
        
                ... on GenericTopicRecord {
                  id  
                  title
                description
                iconName
                }
              }
              imageArea {
                ... on GenericImageContainerRecord {
                  id
                  imageMobile {
                    url
                    alt
                  }
                  imageTablet {
                    url
                    alt
                  }
                  imageDesktop {
                    url
                    alt
                  }
                }
              }
              componentName
            }
            ... on GeneralFaqSectionRecord {
              id
              title
              ctaLink
              ctaText
              faqItems {
                slug
                title
                content
              }
              componentName
            }
          }
        }
      }
    `,
    variables: {
      locale,
    },
  });

  return {
    requestUltravioletPage: messages.requestUltravioletPage,
  };
}
